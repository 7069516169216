import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalSnackbar, GlobalSnackbarProps, GlobalSnackbarSeverity } from 'models/globalSnackbar/GlobalSnackbar';

const initialState: GlobalSnackbar = {
    open: false,
    message: '',
    severity: GlobalSnackbarSeverity.INFO,
    title: '',
};

const sliceGlobalSnackbar = createSlice({
    name: 'GlobalSnackbar',
    initialState,
    reducers: {
        openSnackbar(state: GlobalSnackbar, action: PayloadAction<GlobalSnackbarProps>): void {
            state.open = true;
            state.message = action.payload.message;
            state.title = action.payload.title;
            state.severity = action.payload.severity;
        },
        removeSnackbar(state) {
            state.open = false;
            state.message = '';
        },
    },
});

export const { reducer } = sliceGlobalSnackbar;

export default sliceGlobalSnackbar;
