export const encodeStringToBase64 = (str: string) => {
    const fn = '[encodeStringToBase64]';
    try {
        const binString = String.fromCodePoint(...new TextEncoder().encode(str));
        return btoa(binString);
    } catch (e) {
        console.warn(`${fn}: failed to encode string`, e);
    }
};

export const decodeBase64ToString = (encodedString: string) => {
    const fn = '[decodeBase64ToString]';
    try {
        const binString = atob(encodedString);
        return new TextDecoder().decode(Uint8Array.from(binString, (m) => m?.codePointAt(0) || 0));
    } catch (e) {
        console.warn(`${fn}: failed to decode string`, e);
    }
};

export const testAndDecodeBase64ToString = (encodedString: string) => {
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    const isbase64: boolean = base64regex.test(encodedString);
    if (isbase64) {
        const binString = atob(encodedString);
        return new TextDecoder().decode(Uint8Array.from(binString, (m) => m?.codePointAt(0) || 0));
    } else {
        return encodedString;
    }
};
