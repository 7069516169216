import axios from 'axios';
import { MODAL_TYPES } from 'constants/modalTypes';
import { openModal } from 'redux-state/modals/actions';
import store from 'redux-state/store';
import { checkURLForBlacklist } from './localStagingWarningHelpers';
import { openDebugSnackbar } from 'redux-state/debugSnackbar/actions';
import Cookies from 'js-cookie';
const axiosInstance = axios.create({
    timeout: 600000,
    withCredentials: false,
});

axiosInstance.interceptors.request.use(async (request) => {
    //extra logic to check if local host is point to staging
    request.headers['x-waf-whitelist'] = 'lilyai-whitelist';
    request.headers['Content-Type'] = 'application/json';
    request.headers.Authorization = `Bearer ${Cookies.get('lilytoken')}`;
    if (request.method && request.url && updateMethods.includes(request.method) && checkURLForBlacklist(request.url)) {
        //opens modal if local is talking to staging
        const waitingRequestPromise = new Promise<any>((resolve, reject) => {
            store.dispatch(
                openModal(MODAL_TYPES.LOCAL_STAGING_API_REQUEST_WARN, {
                    onSubmit: () => resolve(''),
                    onReject: () => reject(new Error('Request has been cancelled')),
                })
            );
        });

        try {
            await waitingRequestPromise;
        } catch (e: any) {
            return e;
        }
    }
    return request;
});

const updateMethods: string[] = ['post', 'patch', 'put', 'delete'];

axiosInstance.interceptors.response.use(
    (response) => response,

    (error) => {
        store.dispatch(openDebugSnackbar(error && error.response));
        return Promise.reject((error.response && error.response.data) || 'Something went wrong with fetching data. No response received.');
    }
);

export default axiosInstance;
