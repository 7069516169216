import { lazy } from 'react';
import { Navigate, Outlet, RouteObject, createBrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import App from '../App';
import SegmentWrapper from 'wrappers/SegmentWrapper';
import FeatureFlagGuard from './FeatureFlagGuard';
import Loadable from './Loadable';

//Initalize
const LoginData = Loadable(lazy(() => import('../containers/Login')));

//Catalog Manager
const CatalogManagerPage = Loadable(lazy(() => import('v2/pages/CatalogManager')));

//PAM and search
const SearchAPIComponent = Loadable(lazy(() => import('../containers/SearchApi')));
const PAM = Loadable(lazy(() => import('../containers/PAM')));
const ProductCopyComponent = Loadable(lazy(() => import('../containers/ProductCopy')));

//internal tools
const ProxyProduct = Loadable(lazy(() => import('../containers/ProxyProduct/index')));
const ProxyProductAudit = Loadable(lazy(() => import('../containers/ProxyProduct/audit')));

//Errors
const NotFound = Loadable(lazy(() => import('../components/404NotFound')));
const NotPermitted = Loadable(lazy(() => import('../components/401NoPermission')));
const BadRequest = Loadable(lazy(() => import('../components/400BadRequest')));
const ErrorBoundaryPage = Loadable(lazy(() => import('../components/ErrorBoundaryPage')));

//Analytics
const AnalyticsOverviewContainer = Loadable(lazy(() => import('../containers/AnalyticsOverviewContainer')));
const ConversionsContainer = Loadable(lazy(() => import('../containers/ConversionContainer')));
const Trends = Loadable(lazy(() => import('../containers/TrendsContainer')));

// Integrations
const IntegrationsDashboard = Loadable(lazy(() => import('v2/pages/integrations')));
const IntegrationConfigure = Loadable(lazy(() => import('v2/pages/integrations/Configure')));
const PartnerExport = Loadable(lazy(() => import('v2/pages/integrations/ExportPartner')));

const ErrorBoundaryLayout = () => (
    <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
        <Outlet />
    </ErrorBoundary>
);

export const routes: RouteObject[] = [
    {
        element: <ErrorBoundaryLayout />,
        children: [
            {
                path: 'login',
                errorElement: <BadRequest />,
                element: <LoginData />,
            },
            {
                path: '404',
                element: (
                    <SegmentWrapper name="NotFound">
                        <NotFound />
                    </SegmentWrapper>
                ),
            },
            {
                path: '401',
                element: (
                    <SegmentWrapper name="NotPermitted">
                        <NotPermitted />
                    </SegmentWrapper>
                ),
            },
            {
                path: '400',
                element: (
                    <SegmentWrapper name="BadRequest">
                        <BadRequest />
                    </SegmentWrapper>
                ),
            },
            {
                path: '*',
                element: <App />,
                children: [
                    {
                        path: '',
                        element: (
                            <SegmentWrapper name="CatalogManager">
                                <CatalogManagerPage />
                            </SegmentWrapper>
                        ),
                    },
                    // fallback legacy "/attributes" url to new catalogmanager screen
                    {
                        path: 'attributes',
                        element: (
                            <SegmentWrapper name="Attributes">
                                <FeatureFlagGuard
                                    Component={CatalogManagerPage}
                                    permissionNeeded="PamGalleryStatus20231204"
                                    RedirectComponent={CatalogManagerPage}
                                />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'catalogmanager',
                        element: (
                            <SegmentWrapper name="CatalogManager">
                                <CatalogManagerPage />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'productcopy',
                        element: (
                            <SegmentWrapper name="ProductCopy">
                                <FeatureFlagGuard Component={ProductCopyComponent} permissionNeeded="CopyProductPerm20231208" />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'analytics',
                        element: (
                            <SegmentWrapper name="Analytics2.1">
                                <FeatureFlagGuard
                                    Component={AnalyticsOverviewContainer}
                                    permissionNeeded="isAnalytics21EnabledPermAnalytics20231205"
                                />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'trends',
                        element: (
                            <SegmentWrapper name="Trends">
                                <Trends />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'conversions',
                        element: (
                            <SegmentWrapper name="Conversions2.1">
                                <FeatureFlagGuard Component={ConversionsContainer} permissionNeeded="isAnalytics21EnabledPermAnalytics20231205" />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'proxyproduct',
                        element: (
                            <SegmentWrapper name="Proxy Products">
                                <FeatureFlagGuard Component={ProxyProduct} permissionNeeded="proxyProductStatusPermProxyProduct20221118" />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'proxyproduct/edit',
                        element: <FeatureFlagGuard Component={ProxyProductAudit} permissionNeeded="proxyProductStatusPermProxyProduct20221118" />,
                    },
                    {
                        path: 'integrations',
                        element: (
                            <SegmentWrapper name="Integrations">
                                <FeatureFlagGuard Component={IntegrationsDashboard} permissionNeeded="Integrations20240529" />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'integrations/connect/:brandId',
                        element: (
                            <SegmentWrapper name="Integration Preview">
                                <IntegrationConfigure />
                            </SegmentWrapper>
                        ),
                    },
                    {
                        path: 'integrations/export/:brandId',
                        element: (
                            <SegmentWrapper name="Partner Integration Export">
                                <PartnerExport />
                            </SegmentWrapper>
                        ),
                    },
                ],
            },
        ],
    },
];

const Router = createBrowserRouter(routes);

export default Router;
